<template>
  <div class="home">
    <van-action-sheet v-model:show="show" :actions="actions" @select="onSelect" />






  <van-button square text="复制" type="success" class="delete-button"  @click='clickBtn(value)'/>


  <div id="copytext">
      <p>我是富文本</p>
      <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fattach.bbs.miui.com%2Fforum%2F201311%2F22%2F172342ehscjzq4jbpjjnt4.jpg&refer=http%3A%2F%2Fattach.bbs.miui.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1632905099&t=8803b0fd1e0b0ef1a926917553bf0d04"/>
  </div>


  </div>
</template>

<script>

import useClipboard from 'vue-clipboard3'
export default {
  name: 'About',
  components: {
  },
  data() {
    return {
      msg: '',
      show: true,
      actions: [
        { name: '选项' },
        { name: '选项' },
        { name: '选项', subname: '描述信息' }
      ],
      btnCopy:'',
    }
  },
  created() {
            // this.$toast.success(111);
       
  },
  setup() {
	 //对原始数据进行处理，拼接成自己需要的格式
	 function clickBtn(value){
      console.log(value)
      //处理数据格式
      let Msg = '编号: dsafasdfsadfasd的\r\n撒发送卡'
      //调用复制操作函数
      copy(Msg)
    }	
    const { toClipboard } = useClipboard()
    const copy = async (Msg) => {
      try {
      	//复制
        await toClipboard(Msg)
        console.log(Msg)
        //下面可以设置复制成功的提示框等操作
        //...
      } catch (e) {
      	//复制失败
        console.error(e)
      }
    }
    return { clickBtn }
  },

  methods: {
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      // Toast(item.name);
      console.log(item.name);
    },
    onCancel() {
      alter(1)
    },
    showPopup() {
      this.show = true;
    },
  },
  computed: {
    // 方法一：--------------------------------------
    // count(){
    //   return this.$store.state.count
    // },

    //方法二：mapState-------------------------------
    //写法1：（mapState中用对象的形式获取）
    // ...mapState({
    //   count:state=>state.count
    // })
    // 写法2：mapState中用数组的形式获取）：
    // ...mapState(["token"])
  },
}
</script>
